import React, { useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import Navbar from "../NavBar";
import "../../styles/landingPageAreas/Home.scss";


const CountdownTime = new Date(2025, 1, 6, 13, 0, 0);

const getTimedelta = () => {
    const now = new Date();
    const timedelta = CountdownTime.getTime() - now.getTime();

    const days = Math.floor(timedelta / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timedelta % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timedelta % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timedelta % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds, };
};

function Home() {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [countdownTime, setCountdownTime] = useState(getTimedelta());

    useEffect(() => {
        const video = videoRef.current;

        const interval = setInterval(() => {
            const timedelta = getTimedelta();
            setCountdownTime(timedelta);
        }, 1000);


        if (video) {
            video.play();
            const handleEnded = () => {
                video.currentTime = video.duration;
            };
            video.addEventListener('ended', handleEnded);

            return () => {
                video.removeEventListener('ended', handleEnded);
            };
        }

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="Home">
            <video
                ref={videoRef}
                className="background-video"
                playsInline
                muted
                autoPlay
                loop={false}
            >
                <source src="/video.mp4" type="video/mp4" />
            </video>

            <Box className="timer">
                <h1 className="coming-soon">COMING SOON</h1>
                <Box className="timer-values">
                    <div className="timer-item">
                        <span className="timer-value">{countdownTime.days}</span>
                        <span className="timer-label">DAYS</span>
                    </div>
                    <div className="timer-item">
                        <span className="timer-value">{countdownTime.hours}</span>
                        <span className="timer-label">HOURS</span>
                    </div>
                    <div className="timer-item">
                        <span className="timer-value">{countdownTime.minutes}</span>
                        <span className="timer-label">MINUTES</span>
                    </div>
                    <div className="timer-item">
                        <span className="timer-value">{countdownTime.seconds}</span>
                        <span className="timer-label">SECONDS</span>
                    </div>
                </Box>
            </Box>

        </div>
    );
}

export default Home;