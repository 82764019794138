import './styles/App.scss';
import {BrowserRouter as Router, Routes, Route, Link} from "react-router-dom";
import ProjectDetail from './pages/ProjectDetailPage';
import LandingPage from './pages/LandingPage';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<LandingPage/>}/>
                <Route path="/home" element={<LandingPage anchor="home" />} />
                <Route path="/about" element={<LandingPage anchor="about" />} />
                <Route path="/schedule" element={<LandingPage anchor="schedule" />} />
                <Route path="/projects" element={<LandingPage anchor="projects" />} />
                <Route path="/impressum" element={<LandingPage anchor="impressum" />} />
                <Route path="/project/:id" element={<ProjectDetail/>}/>
            </Routes>
        </Router>
    );
};

export default App;