import '../styles/LandingPage.scss';
import { useEffect } from 'react';
import { Element, scroller } from "react-scroll";
import Home from "../components/landingPageAreas/Home";
import About from "../components/landingPageAreas/About";
import Schedule from "../components/landingPageAreas/Schedule";
import Projects from "../components/landingPageAreas/Projects";
import Impressum from "../components/landingPageAreas/Impressum";

interface LandingPageProps {
    anchor?: string;
}

function LandingPage({ anchor }:  LandingPageProps) {
    useEffect(() => {
        if (anchor) {
            scroller.scrollTo(anchor, {
                duration: 800,
                delay: 0,
                smooth: 'easeInOutQuart'
            });
        }
    }, [anchor]);

    return (
        <main>
            <Element name="home">
                <Home />
            </Element>
        </main>
    );
}

export default LandingPage;
