import { ProjectService } from "../services/projectService";
import "../styles/ProjectDetail.scss";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ProjectDetail } from "../model/apiTypes";

const projectService = new ProjectService(process.env.REACT_APP_API_URL as string);

const ProjectDetailPage: React.FC = () => {
    const [project, setProject] = useState<ProjectDetail>();
    const [error, setError] = useState<string | null>(null);
    const [isVideoError, setIsVideoError] = useState(false);
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    useEffect(() => {
        const fetchProject = async () => {
            try {
                const response = await projectService.getProjectById(Number(id));
                const project = response.data;
                setProject(project);
            } catch (err) {
                setError((err as Error).message);
            }
        };

        fetchProject();
    }, []);

    const handleVideoError = () => {
        setIsVideoError(true);
    };

    const handleBackClick = () => {
        navigate("/");
    };

    const handleImageClick = (imageSource: string) => {
        setSelectedImage(imageSource);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedImage(null);
    };

    return (
        <div className="ProjectDetail" id="projectDetail">
            <div className="header">
                <i className="material-icons" onClick={handleBackClick} style={{ cursor: "pointer" }}>
                    arrow_back
                </i>
                <h1>Projekt - {project?.title}</h1>
            </div>

            <div className="body">
                <div className="video-content-area">
                    <div className="left-column">
                        <div className="video-container">
                            {project?.video && project.video.length > 0 ? (
                                <div>
                                    {error && <p style={{ color: "red" }}>{error}</p>}
                                    {isVideoError ? (
                                        <div
                                            style={{
                                                width: "600px",
                                                height: "340px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                backgroundColor: "#f0f0f0",
                                                color: "#ff0000",
                                                border: "1px solid #ccc",
                                                borderRadius: "8px",
                                            }}
                                        >
                                            Video not found
                                        </div>
                                    ) : (
                                        <video
                                            src={`${process.env.REACT_APP_BASE_URL}${project.video[project.video.length - 1].source}`}
                                            controls
                                            autoPlay
                                            onError={handleVideoError}
                                        >
                                            Your browser does not support the video tag.
                                        </video>
                                    )}
                                </div>
                            ) : (
                                <div
                                    style={{
                                        width: "600px",
                                        height: "340px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        backgroundColor: "#f0f0f0",
                                        color: "#ff0000",
                                        border: "1px solid #ccc",
                                        borderRadius: "8px",
                                    }}
                                >
                                    Video not found
                                </div>
                            )}
                        </div>
                        <div className="project-description">
                            <h2>Beschreibung</h2>
                            <p>{project?.description}</p>
                        </div>
                    </div>
                    <div className="right-column">
                        <h2>Studenten</h2>
                        {project?.students.map((student) => (
                            <div key={student.id} className="group-member">
                                <div className="student-name">{student.firstname} {student.lastname}</div>
                                <div className="studnent-split-line"></div>
                                <div className="student-role">{student.role}</div>
                            </div>
                        ))}
                        <h2>Betreuer</h2>
                        {project?.supervisor.map((supervisor) => (
                            <div key={supervisor.id} className="group-member">
                                <div className="student-name">{supervisor.firstname} {supervisor.lastname}</div>
                            </div>
                        ))}
                        {project?.nominated_categories && project.nominated_categories.length > 0 && (
                            <div className="nominated-categories">
                                <h2>Nominiert</h2>
                                <div>
                                    {project.nominated_categories.map((category) => (
                                        <div key={category.id} className="nomination">
                                            <a>{category.title}</a>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="gallery-area">
                    <h1>Gallerie</h1>
                    <div className="gallery-images">
                        {project?.media.map((media) => (
                            <img
                                key={media.id}
                                src={`${process.env.REACT_APP_BASE_URL}${media.source}`}
                                alt="Gallery"
                                onClick={() => handleImageClick(media.source)}
                            />
                        ))}
                    </div>
                </div>
            </div>

            {isModalOpen && selectedImage && (
                <div className="image-modal" onClick={closeModal}>
                    <div className="modal-content">
                        <img src={`${process.env.REACT_APP_BASE_URL}${selectedImage}`} alt="Selected" />
                    </div>
                </div>
            )}

            <div className="background"></div>
        </div>
    );
};

export default ProjectDetailPage;
