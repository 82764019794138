import axios, { AxiosResponse } from "axios";
import { ApiResponse, Project, ProjectDetail } from "../model/apiTypes";


export class ProjectService {
    private baseURL: string;

    constructor(baseURL: string) {
        this.baseURL = baseURL;
    }

    async getProjects(): Promise<ApiResponse<Project[]>> {
        const response: AxiosResponse<ApiResponse<Project[]>> = await axios.get<ApiResponse<Project[]>>(`${this.baseURL}/projects`);
        return response.data;
    }

    async getProjectById(id: number): Promise<ApiResponse<ProjectDetail>> {
        const response: AxiosResponse<ApiResponse<ProjectDetail>> = await axios.get<ApiResponse<ProjectDetail>>(`${this.baseURL}/projects/${id}`);
        return response.data;
    }
}